import { createColumnHelper } from '@tanstack/vue-table'
import IndeterminateCheckbox from '../IndeterminateCheckbox'
import { withModifiers } from 'vue'

const columnHelper = createColumnHelper<any>()
export const createSelectionColumn = () =>
  columnHelper.display({
    id: 'select',
    size: 16,
    minSize: 16,
    maxSize: 16,
    meta: {
      fixed: 'left',
    },
    enableResizing: false,
    header: ({ table }) => {
      return (
        <IndeterminateCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      )
    },
    cell: ({ row }) => {
      return (
        <IndeterminateCheckbox
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      )
    },
  })
